import React, { useEffect, useState } from 'react';
import { fetch } from '../utils/httpUtil';
import { countries } from '../constants/countries';
import { logError, checkError } from '../utils/helpers';
import {
    Form,
    FormGroup,
    Row,
    Col,
    Label,
    Input,
    Button,
    Alert
} from "reactstrap";


import "../css/form.css";

const GuestDetailsForm = ({ guestDetails, setGuestDetails }) => {
    const [arrReferences, setArrReferences] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();

    const [phoneQuery, setPhoneQuery] = useState(null); 
    const [phoneResults, setPhoneResults] = useState([]);

    useEffect(() => {
        fetchReferences();
        if (guestDetails.guestId !== null) {
            populateGuestDetails(guestDetails.guestId);
        }   
    }, []); 


    function fetchReferences() {  
        setArrReferences([]);       
        fetch("apiReferrals")
            .then((response) => checkError(response))
            .then((result) => {
                console.log(result)
                setArrReferences(result);
            })
            .catch((error) => {
                logError(error, 'GuestDetails', 'fetchReferences');
            });
    }

    function populateReferences() {
        let items = [];   
        items.push(<option key={0} value={0}>Select</option>);   
        for (let i = 0; i < arrReferences.length; i++) {             
            items.push(<option key={i} value={arrReferences[i].reference_id}>{arrReferences[i].referred_by}</option>);   
        }
        return items;
    }

    function populateCountries() {
        let items = [];   
        items.push(<option key={0} value={0}>Select</option>); 
        for (let i = 1; i <= 249; i++) {             
            items.push(<option key={i} value={i}>{countries[i]}</option>);   
        }
        return items;
    }  

    function handlePhoneChange(e) {

        setGuestDetails({ ...guestDetails, phoneNo: e.target.value })

        setPhoneQuery(e.target.value);  

        if (e.target.value.length > 5) {
            if (e.target.value.length % 2 === 0) {
                getPhoneInfo();
            }
        }

        if (e.target.value.toString().trim() === ""){
            document.getElementById("divPhoneSuggestions").style.visibility = "hidden";
        }       
    }

    const getPhoneInfo = () => {
        fetch("apiGuests/?phone=" + phoneQuery)
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {
            setPhoneResults(result);    
            if (result.length !== 0){
                document.getElementById("divPhoneSuggestions").style.visibility = "visible";
            } else {    
                document.getElementById("divPhoneSuggestions").style.visibility = "hidden";
            }    
        })
        .catch((error) => {
            setAlertColor("danger");    
            setAlertMsg("Oops! Something went wrong! Please try again!");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            logError(constructor.name + " " + error);
          });
    }

    function populatePhoneSuggestions(){    
        if (phoneResults.length !== 0){
            document.getElementById("divPhoneSuggestions").style.visibility = "visible";
        }

        let items = []; 

        for (let i = 0; i < phoneResults.length; i++) {             
            items.push(<li key={i} value={i} onClick={() => fillGuestId(phoneResults[i].guest_id)}>{phoneResults[i].guest_name + " - " + phoneResults[i].phone_no}</li>);    
        }
        return items;
    }

    function fillGuestId(guestId){  
        setPhoneQuery(null);
        setPhoneResults([]);     

        document.getElementById("divPhoneSuggestions").style.visibility = "hidden";
        populateGuestDetails(guestId);
    }

    function populateGuestDetails(guestId){ 
        fetch("apiGuests/" + guestId)   
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {   
            setGuestDetails({
                guestId: guestId,    
                referenceId: result[0].reference_id,
                idNumber: result[0].id_number,  
                firstName: result[0].first_name,
                lastName: result[0].last_name,
                phoneNo: result[0].phone_no,
                emailId: result[0].email_id,    
                address: result[0].address, 
                city: result[0].city,
                zipCode: result[0].zip_code,    
                state: result[0].state, 
                ecGuestId: result[0].guest_emergency_contact_id,   
                ecFirstName: result[0].e_first_name,   
                ecLastName: result[0].e_last_name,
                ecPhoneNo: result[0].e_phone_no,
                ecRelationship: result[0].e_relationship,
                countryId: result[0].country_id
            });
        })
        .catch((error) => {
            setAlertColor("danger");    
            setAlertMsg("Oops! Something went wrong! Please try again!");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            logError(constructor.name + " " + error);
        });
    }

    return (
        <div className="divFormLayout">
            <Alert color={alertColor} isOpen={showAlert}>
                {alertMsg}
            </Alert>

                <Row style={{marginTop:'-1em'}}>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">Referred By:</Label>
                            <Input type="select" className="form-control" value={guestDetails.referenceId} onChange={(e) => setGuestDetails({ ...guestDetails, referenceId: e.target.value })}>
                                {arrReferences.length > 0 ? populateReferences() : <option>Loading...</option>}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">ID No:</Label>
                            <Input type="text" className="form-control" value={guestDetails.idNumber} onChange={(e) => setGuestDetails({ ...guestDetails, idNumber: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>    
                        <FormGroup>
                                <Label className="form-label">Phone No:</Label>
                                <Input type="number" required className="form-control" value={guestDetails.phoneNo} onChange={(e) => handlePhoneChange(e)} />
                        </FormGroup>
                        <div id="divPhoneSuggestions" className="autocomplete" style={{ visibility: phoneResults.length !== 0 ? 'visible':'hidden'}}>
                            <ul>
                            {(guestDetails.phoneNo !== "")? populatePhoneSuggestions(): null} 
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop:'-1em'}}>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">First Name:</Label>
                            <Input type="text" required className="form-control" value={guestDetails.firstName} onChange={(e) => setGuestDetails({ ...guestDetails, firstName: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">Last Name:</Label>
                            <Input type="text" required className="form-control" value={guestDetails.lastName} onChange={(e) => setGuestDetails({ ...guestDetails, lastName: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">Email ID:</Label>
                            <Input type="email" className="form-control" value={guestDetails.emailId} onChange={(e) => setGuestDetails({ ...guestDetails, emailId: e.target.value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{marginTop:'-1em'}}>
                    <Col md={8}>
                        <FormGroup>
                            <Label className="form-label">Address:</Label>
                            <Input type="text" className="form-control" value={guestDetails.address} onChange={(e) => setGuestDetails({ ...guestDetails, address: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4} style={{marginTop:'-1em'}}>
                        <FormGroup>
                            <Label className="form-label">Country:</Label>
                            <Input type="select" className="form-control" value={guestDetails.countryId} onChange={(e) => setGuestDetails({ ...guestDetails, countryId: e.target.value })}>
                                {populateCountries()}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{marginTop:'-1em'}}>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">City:</Label>
                            <Input type="text" className="form-control" value={guestDetails.city} required onChange={(e) => setGuestDetails({ ...guestDetails, city: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">Zip Code:</Label>
                            <Input type="text" className="form-control" value={guestDetails.zipCode} onChange={(e) => setGuestDetails({ ...guestDetails, zipCode: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="form-label">State:</Label>
                            <Input type="text" className="form-control" value={guestDetails.state} onChange={(e) => setGuestDetails({ ...guestDetails, state: e.target.value })} />
                        </FormGroup>
                    </Col>
                </Row>

                <h6 style={{ margin: '1em 0 1em 2em' }}>Emergency Contact</h6>
                <Row style={{marginTop:'-1em'}}>
                    <Col md={3}>
                        <FormGroup>
                            <Label className="form-label">First Name:</Label>
                            <Input type="text" className="form-control" value={guestDetails.ecFirstName} onChange={(e) => setGuestDetails({ ...guestDetails, ecFirstName: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label className="form-label">Last Name:</Label>
                            <Input type="text" className="form-control" value={guestDetails.ecLastName} onChange={(e) => setGuestDetails({ ...guestDetails, ecLastName: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label className="form-label">Phone No:</Label>
                            <Input type="text" className="form-control" value={guestDetails.ecPhoneNo} onChange={(e) => setGuestDetails({ ...guestDetails, ecPhoneNo: e.target.value })} />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label className="form-label">Relationship:</Label>
                            <Input type="text" className="form-control" value={guestDetails.ecRelationship} onChange={(e) => setGuestDetails({ ...guestDetails, ecRelationship: e.target.value })} />
                        </FormGroup>
                    </Col>
                </Row>
        </div>
    );
};

export default GuestDetailsForm;
