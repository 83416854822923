import React, { useState, useEffect } from "react";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { Alert } from 'reactstrap'; 
import Header from './Header';
import { checkError, logError } from '../utils/helpers';
import {fetch} from '../utils/httpUtil';   
import { useNavigate } from "react-router-dom"; 

function EventsCalendar() {

    const navigate = useNavigate(); 

    const [items, setItems] = useState([]);  
    const [groups, setGroups] = useState([      
        { id: 1, title: 'Yoga Hall - Ground Floor' },
        { id: 2, title: 'Yoga Hall - 1st Floor' },
        { id: 3, title: 'Yoga Hall - 2nd Floor' },
        { id: 4, title: 'New Yoga Hall' },
        { id: 5, title: 'Rudraksha Garden' },
        { id: 6, title: 'Ghat Pandal' },
        { id: 7, title: 'Satsang Hall' },
        { id: 8, title: 'Samadhi Mandir' },
        { id: 9, title: 'Event Garden' },
        { id: 10, title: 'Ghat Havan Kund' },
        { id: 11, title: 'Navagraha Mandir Area' },
        { id: 12, title: 'Saraswati Garden' },
        { id: 13, title: 'Saraswati Hall'},
        { id: 14, title: 'Kaveri Hall'},
        { id: 15, title: 'Saraswati Mini Hall'}
    ]);

    const [alertMsg, setAlertMsg] = useState();
    const [alertColor, setAlertColor] = useState();
    const [showAlert, setShowAlert] = useState(false);

    const [visibleTimeStart, setVisibleTimeStart] = useState(moment().startOf('month').valueOf());
    const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment().endOf('month').valueOf());   

    useEffect(() => {   
        fetchBookedEventsForMonth();    
    }, [visibleTimeStart]); 


    const fetchBookedEventsForMonth = async () => { 

        const startDate = moment(visibleTimeStart).format('YYYY-MM-DD');    

        fetch(`apiEventhalls/?sdate=${startDate}`)
        .then((response) => checkError(response))
        .then((result) => {  
            if (result.length === 0) {  
                return;
            }

            const arrEvents = result.map((item) => {    
                return {
                    id: item.room_booking_id,
                    group: item.event_hall_id,  
                    rId: item.reservation_id,   
                    rTypeId: item.reservation_type_id,  
                    rStatusId: item.reservation_status_id,
                    gId: item.guest_id, 
                    title: item.guest_name,
                    start_time: moment(item.start_date),
                    end_time: moment(item.end_date)
                }
            });  
            setItems(arrEvents);    

        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000); 
            logError(error);
        });
    };

    const handlePrevMonth = () => {
        const newStart = moment(visibleTimeStart).subtract(1, 'month').startOf('month').valueOf();
        const newEnd = moment(visibleTimeEnd).subtract(1, 'month').endOf('month').valueOf();
        setVisibleTimeStart(newStart);
        setVisibleTimeEnd(newEnd);
    };

    const handleNextMonth = () => {
        const newStart = moment(visibleTimeStart).add(1, 'month').startOf('month').valueOf();
        const newEnd = moment(visibleTimeEnd).add(1, 'month').endOf('month').valueOf();
        setVisibleTimeStart(newStart);
        setVisibleTimeEnd(newEnd);
    };

    const editItem = (itemId) => {
        const item = items.find(item => item.id === itemId);

        const reservationID = item ? item.rId : null;
        const rTypeID = item ? item.rTypeId : null;  
        const rStatusID = item ? item.rStatusId : null; 
        const gID = item ? item.group : null;   
        const guestName = item ? item.title : null;

        const reservationItem = {
            rTypeId: rTypeID, 
            rStatusId: rStatusID, 
            rId: reservationID, 
            gId: gID, 
            guestName: guestName
          }
      
          navigate("/reservationContainer", { state: {reservationItem } });
    }

    return (
    <>
        <Header selected="Reservations"/>
        <div className="divError">    
                    <div className="divPageContainer">
                        <h3>Event Halls Calendar</h3>  
                        <hr/>
                        <Alert color={alertColor} isOpen={showAlert}>  
                            {alertMsg}
                        </Alert>

                        <div>
                        <div className="divFloatRight" style={{marginTop:'-4em'}}>
                                <button onClick={handlePrevMonth} className="btnBig" style={{marginTop:'0', marginLeft:'2em', backgroundColor:'cornflowerblue'}}>Previous Month</button>
                                <button onClick={handleNextMonth} className="btnBig" style={{marginTop:'0', marginLeft:'2em', backgroundColor:'cornflowerblue'}}>Next Month</button>
                        </div>  
                      

                                <Timeline
                                    groups={groups}
                                    items={items}

                                    visibleTimeStart={visibleTimeStart}
                                    visibleTimeEnd={visibleTimeEnd}

                                    onItemClick = {(itemId) => editItem(itemId)}       
                                    
                                    sidebarWidth={200}
                                >
                                        <TimelineHeaders className="sticky" style={{ backgroundColor: 'unset', fontWeight:'bold' }}>
                                        <SidebarHeader>
                                        {({ getRootProps }) => {
                                        return <div {...getRootProps()}></div>;
                                        }}
                                    </SidebarHeader>
                                    <DateHeader unit="primaryHeader" style={{ backgroundColor: 'rosybrown', fontWeight:'bold' }} />
                                    <DateHeader style={{ backgroundColor: 'wheat', fontWeight:'bold' }} />
                                    </TimelineHeaders>
                                </Timeline>
                        </div>
                    </div>
        </div>
    </>
  );
}

export default EventsCalendar;
